@use 'src/css/breakpoint.scss' as *;

.wrap {
  width: 100%;
}

.content {
  display: grid;
  grid-template-columns: 3fr 5fr 3fr;
  column-gap: 70px;
  min-height: 116px;
}

.imgWrap {
  position: relative;

  & img {
    max-height: 166px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.textWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1px;
  padding: 10px 20px;
}

.heading {
  font-weight: 700;
  font-size: 27px;
  line-height: 34px;
  text-transform: uppercase;
  color: #ffffff;
}

.subheading {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.linkWrap {
  display: flex;
  align-items: center;
}

.bannerLink {
  font-weight: 700;
  padding: 20px 60px;
  border-radius: 100px;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.015em;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

@include maxMediaWidth(lg) {
  .heading {
    font-size: 18px;
    line-height: 24px;
  }

  .subheading {
    font-size: 14px;
    line-height: 17px;
  }

  .content {
    grid-template-columns: 3fr 5fr;
  }

  .textWrap {
    padding: 10px 10px 10px 0;
    justify-content: flex-end;
  }

  .imgWrap {
    grid-row: span 2;
  }

  .linkWrap {
    grid-row: 2;
    grid-column: 2;
    padding-bottom: 10px;
  }

  .bannerLink {
    padding: 7px 33px;
    font-size: 14px;
    line-height: 18px;
  }
}

@include maxMediaWidth(sm) {
  .content {
    grid-template-columns: 3fr 4fr;
    gap: 0;
    min-height: 140px;
  }

  .textWrap {
    padding-right: 10px;
  }

  .imgWrap {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    & img {
      position: static;
      max-height: 119px;
      right: 40%;
    }
  }

  .heading {
    line-height: 21px;
  }
}
