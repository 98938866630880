@use 'src/css/breakpoint.scss' as *;

.slider {
  :global(.swiper-wrapper) {
    align-items: stretch;
  }

  :global(.swiper-slide) {
    display: flex;
    justify-content: center;
    height: 100%;
  }
}

.controlsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.pagination {
  display: flex;
  justify-content: center;
  font: var(--font-h8);
  color: var(--pagination-numbers-dark-color);

  :global(.swiper-pagination-current) {
    color: var(--secondary-dark-color);
  }

  :global(.swiper-pagination-bullet) {
    background-color: var(--pagination-dots-normal-dark-color);
    opacity: 1;

    &:hover {
      background-color: var(--pagination-dots-hover-dark-color);
      transition: background-color ease-in 0.1s;
    }
  }

  :global(.swiper-pagination-bullet-active) {
    background: var(--pagination-dots-active-dark-color);
    opacity: 1;
  }

  &:global(.swiper-pagination-disabled),
  &:global(.swiper-pagination-hidden) {
    display: none;
  }
}

.controlButton {
  .arrowIcon {
    font-size: 21px;
    color: var(--secondary-dark-color);
    transition: color ease-in-out 0.2s;
  }

  &:hover .arrowIcon {
    color: var(--secondary-text-color);
  }
}

.prevControl,
.nextControl {
  @include minMediaWidth(lg) {
    display: block;
  }
}
