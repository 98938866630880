.wrap {
  position: absolute;
  top: 80px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px 14px 20px 15px;
  background: var(--calc-notification-bg-dark-color);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.10);

  border-radius: 6px;
  z-index: 1;

  &::before {
      content: '';
      position: absolute;
      top: -10px;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 13px solid var(--calc-notification-bg-dark-color);
  }
}

.wrap_to::before {
  top: auto;
  bottom: -10px;
  transform: rotate(56deg);
}

.wrapIcon {
  width: 14px;
  margin-right: 10px;
  color: var(--secondary-dark-color);
}

.text {
  margin: 0 0 5px;
  font: var(--font-h11);
  color: var(--accented-dark-color);
}

.ticker {
  text-transform: uppercase;
  font-weight: 500;
}

.button {
  padding: 0;
  color: var(--secondary-dark-color);
  font-size: 1.4rem;
  font-weight: var(--weight-medium);
  text-decoration: underline;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
