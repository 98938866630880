.wrap {
  position: relative;
  width: 100%;
}

.rateTypeAndSwapContainer {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}
