@use 'src/css/breakpoint.scss' as *;

.switcher {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
  margin: 0 auto 60px;
  max-width: 400px;

  @include maxMediaWidth(lg) {
    margin-bottom: 56px;
  }

  @include maxMediaWidth(sm) {
    margin-bottom: 30px;
  }
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--font-button-small);
  color: var(--special-dark-color);
  background-color: var(--neutral-button-bg-dark-color);
  border: none;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;

  &:first-child {
    border-radius: 50px 0 0 50px;
  }

  &:nth-child(2) {
    border-radius: 0 50px 50px 0;
  }

  &.active {
    color: var(--main-title-color);
    background-color: var(--secondary-dark-color);
  }
}
