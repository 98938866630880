@use 'src/css/breakpoint.scss' as *;

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.value {
  display: inline-block;
  margin-bottom: 10px;
  font: var(--font-h4);
  text-transform: uppercase;
  color: var(--today-statistics-value-dark-color);

  @include maxMediaWidth(lg) {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h4-sm);
  }
}

.title {
  font: var(--font-h8);
  color: var(--main-title-color);

  @include maxMediaWidth(lg) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h8-sm);
  }
}
