@use 'src/css/breakpoint.scss' as *;

.wrap {
  position: relative;
}

.field {
  display: flex;
  align-items: stretch;
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  &.open {
    overflow: unset;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.label {
  position: absolute;
  top: 14px;
  left: 16px;
  flex: 3 0 auto;
  font: var(--font-h11);
  color: var(--color-primary-light);
}

.wrapDropdownList {
  position: absolute;
  width: 100%;
  max-height: 345px;
  background-color: var(--light-bg-color);
  border-radius: 6px;
  overflow: hidden;
  z-index: 1;
}

.wrapDropdownInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  border-bottom: 1px solid var(--border-color);

  .inputContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .arrow {
    color: var(--secondary-dark-color);
  }
}

.wrapList {
  display: none;
  width: 100%;
  max-height: 268px;
  padding: 14px 0;
  background: var(--light-bg-color);
  overflow: scroll;
  z-index: 1;

  &.open {
    display: block;

    .label {
      display: none;
    }
  }
}

.wrapInput {
  position: relative;
  width: 100%;

  background-color: var(--color-primary-alpha-10);

  &.isError {
    background: var(--color-warning-alpha-20);
  }
}

.wrapLoupe {
  width: 24px;
  height: 24px;
  color: var(--selectCurrencies-icon-color);

  @include maxMediaWidth(lg) {
    width: 20px;
    height: 20px;
  }
}

.input {
  padding: 32px 15px 10px;
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;
}

.input[type='number']::-webkit-outer-spin-button,
.input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type='number'] {
  -moz-appearance: textfield;
}
.input[type='number']:hover,
.input[type='number']:focus {
  -moz-appearance: number-input;
}

.input[type='number']::-webkit-inner-spin-button,
.input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputAmount {
  font: var(--font-calculator-namecoin);
  font-weight: 500;
  text-align: left;
  color: var(--main-title-color);
  background: none;
}

.inputCurrency {
  font: var(--font-h9);
  color: var(--secondary-bg-dark-color);
  background-color: var(--light-bg-color);
  padding: 0 0 0 12px;
}

.wrapSelect {
  --dd-btn-bg: var(--ocean-blue);

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  width: 132px;
  max-width: 132px;
  min-width: 132px;
  background: var(--dd-btn-bg);
  padding: 13px 16px;

  outline: none;
  border: unset;
  cursor: pointer;

  &:hover {
    --dd-btn-bg: var(--color-primary-dimmed);
  }
}

.wrapSelectContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  gap: 5px;
  min-height: 46px;
  overflow-x: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 24px;
    height: 100%;
    pointer-events: none;
    display: none;
  }

  &::after {
    right: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), var(--dd-btn-bg));
  }

  &.hasContentRight::after {
    display: block;
  }
}

.chainLabel {
  margin: 0;
  padding: 3px;
  font: var(--font-blockchain);
  text-align: center;
  text-wrap: nowrap;
}

.selectCurrency {
  font: var(--font-calculator-namecoin);
  color: var(--main-title-color);

  word-break: keep-all;
  text-transform: uppercase;
}

.arrow {
  width: 20px;
  min-width: 20px;
  color: var(--main-title-color);

  &.active {
    transform: rotate(180deg);
    cursor: pointer;
  }
}

.wrapLoader {
  position: absolute;
  bottom: 16px;
  left: 14px;
}

.hidden {
  visibility: hidden;
}
