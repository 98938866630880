@use 'src/css/breakpoint.scss' as *;

.section {
  margin-bottom: 130px;

  @include maxMediaWidth(lg) {
    margin-bottom: 82px;
  }

  @include maxMediaWidth(sm) {
    margin-bottom: 50px;
  }
}

.title {
  text-align: center;
  padding-bottom: 60px;

  @include maxMediaWidth(lg) {
    padding-bottom: 40px;
  }

  @include maxMediaWidth(sm) {
    text-align: left;
    padding-bottom: 25px;
  }
}
