@use 'src/css/breakpoint.scss' as *;

.wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 30px;
  padding: 0;
}

.wrapTitle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.wrapAvatarService {
  position: relative;
  margin-right: 30px;
  flex: 0 0 65px;
  height: 65px;
}

.wrapAvatar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
}

.serviceIcon {
  position: absolute;
  bottom: 0px;
  right: -1px;
}

.author {
  display: block;
  margin-bottom: 6px;
  font: var(--font-h7);
  color: var(--main-title-color);

  @include maxMediaWidth(lg) {
    font: var(--font-h5-md);
    text-transform: uppercase;
  }

  @include maxMediaWidth(sm) {
    font: var(--font-h6-sm);
    text-transform: unset;
  }
}

.data {
  font: var(--font-h11);
  color: var(--secondary-text-color);
}

.text {
  margin: 0;
  font: var(--font-h9);
  color: var(--secondary-text-color);
}

.link {
  outline: none;

  .serviceIcon {
    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: var(--review-item-serviceIcon-bg-color);
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.expand {
  position: relative;
  display: flex;
  margin-top: 30px;
  padding: 5px 19px;
  font: var(--font-button-small);
  color: var(--secondary-text-color);
  background-color: var(--neutral-button-bg-dark-color);
  border-radius: 80px;
  cursor: pointer;
  z-index: 3;
}
