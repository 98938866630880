.item {
  display: flex;
  justify-content: flex-start;

  align-items: center;
  padding: 16px 14px;
  cursor: pointer;

  &.isActive,
  &:hover {
    background: var(--light-menu-bg-hover-dark-color);
  }

  + .title {
    padding-top: 16px;
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px 6px;
}

.ticker {
  margin-right: 4px;
  font: var(--font-h10);
  font-weight: 500;
  text-transform: uppercase;
  color: var(--currencyOption-title-color);
  text-wrap: nowrap;
}

.nameWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 4px;
}

.name {
  font: var(--font-h11);
  color: var(--currencyOption-title-color);
  line-height: 22px;
  text-wrap: wrap;
}

.dropdownItemCurrencyIcon {
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
  border-radius: var(--border-radius-round);
  background: var(--color-primary);
  color: var(--color-foreground);
  margin-right: 14px;
}

.title {
  padding: 0 14px 4px;
  font: var(--font-h13);
  color: var(--currencyOption-title-color);
}

.item .optionChain {
  padding: 4px;
  white-space: nowrap;
}
