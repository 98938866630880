$bg: var(--color-primary-dark);
$bgHover: var(--color-primary-alpha-20);
$bgActive: var(--color-primary);

.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.segmentedButton {
  background: $bg;
  border-radius: var(--border-radius-round);
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.button {
  font: var(--font-h11);

  border-width: 0;
  color: var(--color-foreground);
  background-color: inherit;
  padding: 10px 14px;

  &:hover {
    background-color: var(--color-primary-alpha-20);
  }

  &[aria-pressed='true'] {
    background-color: $bgActive;
  }

  &:first-child {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &:last-child {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
  }
}

.popoverButton {
  --popover-button-color: var(--color-primary);
  --popover-button-background: transparent;

  cursor: pointer;
  background: none;
  width: 32px;
  height: 32px;
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  padding: 3px;
  border-radius: 50%;
  border: none;
  outline-color: var(--popover-button-color);

  &:hover,
  &:focus-visible,
  &:focus-within {
    --popover-button-color: var(--color-secondary);
    --popover-button-background: var(--color-secondary-alpha-20);
  }
}

.popoverButtonInner {
  color: var(--popover-button-color);
  border: 1px solid var(--popover-button-color);
  background-color: var(--popover-button-background);

  aspect-ratio: inherit;
  width: 100%;
  height: 100%;
  font-weight: 500;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.popoverPanel {
  z-index: 2;
  overflow: hidden;

  &::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    background-color: var(--color-background-contrast);
    left: calc(50% - 8px);
    transform: rotate(45deg);
    z-index: 4;
  }

  &ArrowDown {
    padding-bottom: 12px;
    &::after {
      bottom: 8px;
    }
  }

  &ArrowUp {
    padding-top: 12px;
    &::after {
      top: 8px;
    }
  }
}

.popoverPanelInner {
  font: var(--font-h11);
  color: var(--color-foreground-contrast-2);
  max-width: 312px;
  padding: 24px;
  z-index: 10;
  background-color: var(--color-background-contrast);
  // todo: add box shadows to the css vars
  box-shadow: 0 2px 2px 0 #0000001a;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.popoverPanelSectionTitle {
  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-primary);
}

.popoverPanelSectionDescription {
  margin: 0;
  padding-top: 10px;
}
