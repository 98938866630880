@use 'src/css/breakpoint.scss' as *;

.indexSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 80px auto;

  @include maxMediaWidth(sm) {
    margin-bottom: 40px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  text-align: center;
  padding-bottom: 45px;

  @include maxMediaWidth(lg) {
    padding-bottom: 38px;
  }

  @include maxMediaWidth(sm) {
    text-align: left;
    padding-bottom: 25px;
  }
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  column-gap: 82px;
  row-gap: 70px;

  @include maxMediaWidth(lg) {
    grid-template-columns: 1fr 1fr;
    row-gap: 25px;
    column-gap: 37px;
  }

  @include maxMediaWidth(sm) {
    width: 100%;
    column-gap: 9px;
    row-gap: 20px;
  }
}

.imgWrap {
  position: relative;

  @include maxMediaWidth(sm) {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    width: 654px;
    height: 654px;

    border-radius: 654px;
    background: var(--bg-image-gradient);
    filter: blur(50px);
    pointer-events: none;

    @include maxMediaWidth(lg) {
      width: 270px;
      height: 270px;
    }
  }
}

.image {
  object-fit: contain;
  object-position: center;
  position: relative;
  z-index: 1;

  @include maxMediaWidth(lg) {
    width: 168px;
    height: 168px;
  }
}
