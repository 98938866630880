.swapButton {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  padding: 0;
  border: 0;
  background: var(--calc-swap-button-bg-color);
  border-radius: 4px;
  cursor: pointer;
}

.swapIcon {
  color: var(--calc-swap-button-bg-color);

  & svg {
    width: 32px;
    height: 32px;
    overflow: unset;
  }
}
